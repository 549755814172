import { setAxiosToken } from "api/requester"
import { useAppDispatch, useAppSelector } from "app/hooks"
import { updateToken } from "features/auth/authSlice"
import { Fragment, useEffect } from "react"
import { Navigate, Outlet, useNavigate } from "react-router-dom"
import PATHS from "router/paths"

const AuthRoute = () => {
  const token = useAppSelector((state) => state.auth.token)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const urlToken = urlParams.get("token")

    if (urlToken) {
      dispatch(updateToken(urlToken))
      setAxiosToken(urlToken)
      window.history.replaceState({}, document.title, "/home")
      navigate(PATHS.app.root)
    } else if (token) {
      navigate(PATHS.app.root)
    }
  }, [dispatch, token])

  if (token) {
    return <Navigate to={PATHS.app.root} />
  }

  return (
    <Fragment>
      <Outlet />
    </Fragment>
  )
}

export default AuthRoute
