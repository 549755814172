import { USER_UPLOADED_IMAGE_PREFIX } from "config"
import { IFeedback, IMeal, IPortion } from "features/meal/types"
import { IMetricType, IUser } from "features/onboarding/types"

export const getUserUploadedImageUrl = (text: string) => {
  return text.split(USER_UPLOADED_IMAGE_PREFIX)[1]
}

export const mockRequest = <T>(data: T) => {
  return new Promise<T>((resolve) => {
    setTimeout(() => {
      resolve(data as T)
    }, 0)
  })
}

export const getSvgFill = (isSelected: boolean) => {
  const backgroundFill = isSelected ? "rgba(255, 255, 255, 0.20)" : "#F5F7F7"

  const pathFill = isSelected ? "#fff" : "#1C1F21"

  return { backgroundFill, pathFill }
}

export const truncateByDecimalPlace = (value: number, place?: number) => {
  const decimalPlace = place ?? 2
  return (
    Math.trunc(Math.round(value * Math.pow(10, decimalPlace))) /
    Math.round(Math.pow(10, decimalPlace))
  )
}

export const cmToFeet = (cm: number) => {
  const inches = cm / 2.54
  const feet = inches / 12
  return truncateByDecimalPlace(feet)
}

export const gramToPound = (gram: number) => {
  return truncateByDecimalPlace(gram / 453.592)
}

export const poundToGram = (pound: number) => {
  return pound * 453.592
}

export const kgToPound = (kg: number) => {
  return kg * 2.20462
}

export const poundToKg = (pound: number) => {
  return pound / 2.20462
}

export const gramToKg = (gram: number) => {
  return truncateByDecimalPlace(gram / 1000)
}

export const kgToGram = (kg: number) => {
  return truncateByDecimalPlace(kg * 1000)
}

export const mmToCm = (mm: number) => {
  return mm / 10
}

export const cmToMm = (cm: number) => {
  return cm * 10
}

export const feetToCm = (feet: number) => {
  return truncateByDecimalPlace(feet * 30.48)
}

export const formatBirthedOn = (date: string) => {
  const [year, month, day] = date.split("-")
  return `${day}/${month}/${year}`
}

export const titleCase = (string: string) => {
  return string[0].toUpperCase() + string.slice(1).toLowerCase()
}

export const getErrorMessage = (error: any) => {
  return error.response?.data?.message ?? "Something went wrong!"
}

const PLACEHOLDER = "..."

export const getDisplayHeight = (metric: IMetricType, user?: IUser) => {
  if (!user) {
    return PLACEHOLDER
  }

  if (metric === "IMPERIAL") {
    const { feet, inches } = feetToFeetInches(
      Number(user.feetHeight.replace(" ft", "")),
    )
    let displayHeight = `${feet}'`
    if (inches > 0) {
      displayHeight += `${inches}"`
    }

    return displayHeight
  }

  return user.cmHeight
}

export const getWeight = (metric: IMetricType, user?: IUser) => {
  if (!user) {
    return PLACEHOLDER
  }

  return metric === "IMPERIAL" ? user.poundWeight : user.kgWeight
}

export const feetToMm = (feet: number) => {
  const inches = feet * 12
  const mm = inches * 25.4
  return mm
}

export const getCmToDisplay = (height: number, metricType: IMetricType) => {
  return metricType === "METRIC"
    ? height
    : truncateByDecimalPlace(feetToCm(height), 0)
}

export const getFtToDisplay = (height: number, metricType: IMetricType) => {
  return metricType === "IMPERIAL"
    ? height
    : truncateByDecimalPlace(cmToFeet(height))
}

export const getKgToDisplay = (weight: number, metricType: IMetricType) => {
  return metricType === "METRIC"
    ? weight
    : truncateByDecimalPlace(poundToKg(weight), 0)
}

export const getPoundToDisplay = (weight: number, metricType: IMetricType) => {
  return metricType === "IMPERIAL"
    ? weight
    : truncateByDecimalPlace(kgToPound(weight), 0)
}

export const convertMealToFeedback = (meal?: IMeal): IFeedback | undefined => {
  if (!meal) {
    return undefined
  }

  const [first, second] = meal.servings?.split("/") ?? ["1", "1"]

  return {
    ...meal,
    portion: {
      first: Number(first),
      second: Number(second),
    },
  }
}

export const getDisplayedServings = (portion: IPortion) => {
  const { first, second } = portion
  const percent = truncateByDecimalPlace((first / second) * 100, 0)

  if (isNaN(percent) || !isFinite(percent)) {
    return ""
  }

  return percent
}

export function decimalToFraction(decimal: number) {
  // Handle the case where the input is an integer
  if (Number.isInteger(decimal)) {
    return { numerator: decimal, denominator: 1 }
  }

  const tolerance = 1.0e-6 // You can adjust this tolerance
  let numerator = 1
  let denominator = 1

  // Scale the decimal to remove decimal places
  let sign = Math.sign(decimal)
  decimal = Math.abs(decimal)

  while (Math.abs(decimal - numerator / denominator) > tolerance) {
    if (numerator / denominator < decimal) {
      numerator++
    } else {
      denominator++
    }
  }

  // Restore the sign
  numerator *= sign
  return { numerator, denominator }
}

export const getPortionFromServing = (servings: string) => {
  try {
    let [numerator, denominator] = servings.split("/")

    return {
      first: Number(numerator),
      second: denominator ? Number(denominator) : 1,
    }
  } catch (error) {
    console.error(error)
    return {
      first: 1,
      second: 1,
    }
  }
}

export const getDisplayServing = (servings: string) => {
  const [numerator, denominator] = servings?.split("/")
  if (denominator === "1") {
    return numerator
  }

  return servings
}

export const numberWithCommas = (x: number) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

export const getDisplayCalories = (calories?: number) => {
  return numberWithCommas(truncateByDecimalPlace(calories ?? 0, 0))
}

export const getDisplayNumber = (number: number) => {
  return numberWithCommas(truncateByDecimalPlace(number, 0))
}

export const getSignUpUrl = () => {
  const SIGN_UP_URL = import.meta.env.VITE_SIGN_UP_URL
  const REDIRECT_URL = import.meta.env.VITE_REDIRECT_URL

  if (SIGN_UP_URL && REDIRECT_URL) {
    return `${SIGN_UP_URL}/food-journal?redirect_url=${REDIRECT_URL}/auth/login-with-email`
  }

  return undefined
}

export const is404Response = (error: any) => {
  return error.response?.status === 404
}

export const cmToFeetInches = (cm: number) => {
  const totalInches = cm / 2.54 // Convert cm to inches
  const feet = Math.floor(totalInches / 12) // Get whole feet
  const inches = Math.round(totalInches % 12) // Get remaining inches
  return { feet, inches }
}

export const feetInchesToCm = (feet: number, inches: number) => {
  const feetToCm = 30.48 // 1 foot = 30.48 cm
  const inchesToCm = 2.54 // 1 inch = 2.54 cm

  // Convert feet and inches to centimeters
  const totalCm = feet * feetToCm + inches * inchesToCm
  return truncateByDecimalPlace(totalCm)
}

export const feetInchesToFeet = (feet: number, inches: number) => {
  return truncateByDecimalPlace(feet + inches / 12)
}

export const feetToFeetInches = (decimalFeet: number) => {
  // Get the whole number of feet
  const feet = Math.floor(decimalFeet)
  // Get the decimal part and convert it to inches
  const inches = Math.round((decimalFeet - feet) * 12)

  return { feet, inches }
}
