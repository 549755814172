import { createSlice } from "@reduxjs/toolkit"
import mealAPI from "features/meal/mealAPI"
import { createAppAsyncThunk } from "app/hooks"
import {
  FOOD_NAME_PREFIX,
  NETWORK_ERROR_MESSAGE,
  OUT_OF_DAILY_SCAN_MESSAGE,
  resetStoreAction,
  USER_UPLOADED_IMAGE_PREFIX,
  WELCOME_MESSAGE,
} from "config"
import {
  MealState,
  ICreateMealPayload,
  IFeedback,
  IMealResponse,
  IMeal,
  IFeedbackPayload,
  IDuplicateMealPayload,
  IRawMeal,
  IAiOutput,
  IUserOutput,
} from "./types"
import { getStandardIngredients } from "./mealHelper"
import { getPortionFromServing } from "utils"

export const convertServerToClientMeal = (
  mealResponse: IMealResponse,
): IMeal => {
  return {
    ...mealResponse,
    imageUrl: mealResponse.image_url,
    foodType: mealResponse.food_type,
    previewImageUrl: mealResponse.preview_image_url,
    ingredients: mealResponse.ingredients.map((ingredient) => {
      return {
        ...ingredient,
        name: ingredient.ingredient_name,
      }
    }),
  }
}

export const convertServerToClientMeals = (
  rawMeals: IRawMeal[],
): { aiData: IAiOutput[]; userData: IUserOutput[] } => {
  const aiData = rawMeals.map((rawMeal) => ({
    name: rawMeal.ai.name,
    portion: rawMeal.portion,
    unit: rawMeal.ai.unit,
    weight: rawMeal.ai.amount,
  }))

  const userData = rawMeals.map((rawMeal) => ({
    name: rawMeal.name,
    portion: rawMeal.portion,
    unit: rawMeal.unit,
    calorie: rawMeal.calorie,
  }))

  return { aiData, userData }
}

export const convertFeedbackPayload = (
  feedback: IFeedback,
): IFeedbackPayload => {
  return {
    name_of_dish: feedback.name,
    servings: `${feedback.portion.first}/${feedback.portion.second}`,
    ingredients: feedback.ingredients.map((ingredient) => {
      return {
        ingredient_name: ingredient.name,
        amount: ingredient.amount,
        calorie: ingredient.calorie,
        fat: ingredient.fat,
        protein: ingredient.protein,
        carbohydrate: ingredient.carbohydrate,
      }
    }),
  }
}

export const createMeal = createAppAsyncThunk(
  "meal/createMeal",
  async (payload: ICreateMealPayload, { rejectWithValue }) => {
    try {
      const response = await mealAPI.createMeal(payload)

      return convertServerToClientMeal(response)
    } catch (err: any) {
      return rejectWithValue(err)
    }
  },
)

export const createMeals = createAppAsyncThunk(
  "meal/createMeals",
  async (payload: ICreateMealPayload, { rejectWithValue }) => {
    try {
      const response = await mealAPI.createMeals(payload)
      const { aiData, userData } = convertServerToClientMeals(response)

      return { aiData, userData }
    } catch (err: any) {
      return rejectWithValue(err)
    }
  },
)

export const sendFeedback = createAppAsyncThunk(
  "meal/sendFeedback",
  async (feedback: IFeedback, { rejectWithValue }) => {
    try {
      const payload = convertFeedbackPayload(feedback)
      const id = feedback.id
      const meal = await mealAPI.sendFeedback(payload, id)

      return convertServerToClientMeal(meal)
    } catch (err: any) {
      return rejectWithValue(err)
    }
  },
)

export const getIngredientOptions = createAppAsyncThunk(
  "meal/getIngredientOptions",
  async (_, { rejectWithValue }) => {
    try {
      const response = await mealAPI.getIngredients()
      return response
    } catch (err: any) {
      return rejectWithValue(err)
    }
  },
)

const onImageLoaded = (src: string) =>
  new Promise((resolve) => {
    if (!src) resolve(true)
    const img = new Image()
    img.src = src

    img.onload = function () {
      resolve(true)
    }
  })

export const processImage = createAppAsyncThunk(
  "meal/processImage",
  async (file: File, { rejectWithValue }) => {
    try {
      const response = await mealAPI.processImage(file)
      await onImageLoaded(response.url)

      return response
    } catch (err: any) {
      return rejectWithValue(err)
    }
  },
)

export const duplicateMeal = createAppAsyncThunk(
  "meal/duplicateMeal",
  async (
    { id, payload }: { id: string; payload: IDuplicateMealPayload },
    { rejectWithValue },
  ) => {
    try {
      const response = await mealAPI.duplicateMeal(id, payload)
      return response
    } catch (err: any) {
      return rejectWithValue(err)
    }
  },
)

export const getMealDetails = createAppAsyncThunk(
  "meal/getMealDetails",
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await mealAPI.getMealDetails(id)
      return convertServerToClientMeal(response)
    } catch (err: any) {
      return rejectWithValue(err)
    }
  },
)

export const getDailyScanProgress = createAppAsyncThunk(
  "meal/getDailyScanProgress",
  async (_, { rejectWithValue }) => {
    try {
      const response = await mealAPI.getDailyScanProgress()
      return response
    } catch (err: any) {
      return rejectWithValue(err)
    }
  },
)

export const getMealUnits = createAppAsyncThunk(
  "meal/getMealUnits",
  async (_, { rejectWithValue }) => {
    try {
      const response = await mealAPI.getMealUnits()
      return response
    } catch (err: any) {
      return rejectWithValue(err)
    }
  },
)

const initialState: MealState = {
  mealUnits: [],
  getMealUnitsLoading: false,
  getMealUnitsFailed: undefined,

  dailyScanProgress: {
    remainingScan: 0,
  },

  getDailyScanProgressLoading: false,
  getDailyScanProgressFailed: undefined,

  aiData: [],
  userData: [],
  createMealsLoading: false,
  createMealsError: null,
  createMealsFailed: undefined,

  createdMeal: undefined,
  createMealLoading: false,
  createMealFailed: undefined,
  imageBase64Encoded: "",
  convertImageLoading: false,
  step: "init",
  messages: [
    {
      owner: "kagami",
      texts: [WELCOME_MESSAGE],
      isWelcome: true,
    },
  ],
  feedback: undefined,

  sendFeedbackLoading: false,
  sendFeedbackFailed: undefined,
  sendFeedbackSuccess: undefined,
  file: undefined,

  imageUrl: "",
  imageId: "",
  processImageLoading: false,
  processImageFailed: undefined,
  displayImageType: "base64",

  ingredientOptions: [],
  getIngredientOptionsLoading: false,
  getIngredientOptionsFailed: undefined,

  duplicatedMealLoading: false,
  duplicatedMealSuccess: undefined,
  duplicatedMealFailed: undefined,

  mealDetails: undefined,
  getMealDetailsLoading: false,
  getMealDetailsFailed: undefined,

  standardIngredients: [],
}

export const searchSlice = createSlice({
  name: "textChat",
  initialState,
  reducers: {
    setMealDetails(state, { payload }) {
      state.mealDetails = payload
    },

    uploadImage(state, { payload }) {
      state.imageBase64Encoded = payload.base64
      state.file = payload.file
      state.displayImageType = "base64"
      state.step = "uploaded"

      state.imageUrl = ""
      state.imageId = ""
    },

    renameMeal(state, { payload }) {
      if (state.feedback) {
        state.feedback.name = payload

        const nameMessage = state.messages.find((message) =>
          message.texts[0].includes(FOOD_NAME_PREFIX),
        )

        if (nameMessage) {
          nameMessage.texts[0] = FOOD_NAME_PREFIX + payload
        }
      }
    },

    updateFeedback(state, { payload }) {
      state.feedback = {
        ...state.feedback,
        [payload.key]: payload.value,
      } as any
    },

    setStep(state, { payload }) {
      if (payload === "init") {
        return initialState
      }

      state.step = payload
    },

    removeUploadedImage(state) {
      state.file = undefined
      state.imageBase64Encoded = ""
      state.imageId = ""
      state.imageUrl = ""
    },

    resetMealState() {
      return initialState
    },
  },
  extraReducers(builder) {
    builder
      .addCase(createMeal.pending, (state) => {
        state.createMealLoading = true
        state.step = "loading"

        const image =
          state.displayImageType && state.displayImageType === "base64"
            ? state.imageBase64Encoded
            : state.imageUrl

        state.messages = [
          {
            owner: "user",
            texts: [USER_UPLOADED_IMAGE_PREFIX + image],
          },
        ]

        state.imageBase64Encoded = ""
        state.imageUrl = ""
        state.file = undefined
      })
      .addCase(createMeal.fulfilled, (state, { payload }) => {
        state.createMealLoading = false
        state.imageId = ""
        state.imageUrl = ""
        state.imageBase64Encoded = ""
        state.file = undefined

        if (
          payload.status === "NOT_FOOD_PICTURE" ||
          payload.status === "FAILED"
        ) {
          state.step = "not-found"
          return
        }

        const portion = getPortionFromServing(payload.servings)

        state.standardIngredients = getStandardIngredients({
          ...payload,
          portion,
        })

        state.createdMeal = payload
        state.step = "edit"

        state.feedback = {
          ...payload,
          portion,
        }
      })
      .addCase(createMeal.rejected, (state, { payload }) => {
        state.createMealLoading = false
        state.createMealFailed = payload
        state.step = "init"
        state.messages.push(NETWORK_ERROR_MESSAGE)

        state.imageId = ""
        state.imageUrl = ""
        state.imageBase64Encoded = ""
        state.file = undefined
      })

      .addCase(createMeals.pending, (state) => {
        state.createMealsLoading = true
        state.createMealsError = null
        state.step = "loading"

        const image =
          state.displayImageType && state.displayImageType === "base64"
            ? state.imageBase64Encoded
            : state.imageUrl

        state.messages = [
          {
            owner: "user",
            texts: [USER_UPLOADED_IMAGE_PREFIX + image],
          },
        ]
      })
      .addCase(createMeals.fulfilled, (state, { payload }) => {
        state.createMealsLoading = false
        state.aiData = payload.aiData
        state.userData = payload.userData
        state.step = "edit"
      })
      .addCase(createMeals.rejected, (state, { payload }) => {
        state.createMealsLoading = false
        state.createMealsError = payload as string
        state.createMealsFailed = payload
      })

      .addCase(sendFeedback.pending, (state) => {
        state.sendFeedbackLoading = true
      })

      .addCase(sendFeedback.fulfilled, (state, { payload }) => {
        state.sendFeedbackLoading = false
        state.sendFeedbackSuccess = payload
        state.mealDetails = payload
      })
      .addCase(sendFeedback.rejected, (state, { payload }) => {
        state.sendFeedbackLoading = false
        state.sendFeedbackFailed = payload
      })

      .addCase(processImage.pending, (state) => {
        state.processImageLoading = true
        state.imageUrl = ""
        state.imageId = ""
        state.imageBase64Encoded = ""
        state.displayImageType = "url"
        state.step = "uploaded"
      })

      .addCase(processImage.fulfilled, (state, { payload }) => {
        state.imageUrl = payload.url
        state.imageId = payload.id
        state.processImageLoading = false
      })
      .addCase(processImage.rejected, (state, { payload }) => {
        state.processImageLoading = false
        state.imageUrl = ""
        state.imageId = ""
        state.processImageFailed = payload
        state.step = "init"
      })

      .addCase(getIngredientOptions.pending, (state) => {
        state.getIngredientOptionsLoading = true
      })
      .addCase(getIngredientOptions.fulfilled, (state, { payload }) => {
        state.getIngredientOptionsLoading = false
        state.ingredientOptions = payload.map((ingredient) => {
          return {
            ...ingredient,
            weightPerIngredient: ingredient.weight_per_ingredient,
          }
        })
      })
      .addCase(getIngredientOptions.rejected, (state, { payload }) => {
        state.getIngredientOptionsLoading = false
        state.getIngredientOptionsFailed = payload
      })

      .addCase(duplicateMeal.pending, (state) => {
        state.duplicatedMealLoading = true
      })

      .addCase(duplicateMeal.fulfilled, (state, { payload }) => {
        state.duplicatedMealLoading = false
        state.duplicatedMealSuccess = payload
      })
      .addCase(duplicateMeal.rejected, (state, { payload }) => {
        state.duplicatedMealLoading = false
        state.duplicatedMealFailed = payload
      })

      .addCase(getMealDetails.pending, (state) => {
        state.getMealDetailsLoading = true
      })
      .addCase(getMealDetails.fulfilled, (state, { payload }) => {
        state.getMealDetailsLoading = false
        state.mealDetails = payload
      })
      .addCase(getMealDetails.rejected, (state, { payload }) => {
        state.getMealDetailsLoading = false
        state.getMealDetailsFailed = payload
      })

      .addCase(getDailyScanProgress.pending, (state) => {
        state.getDailyScanProgressLoading = true
        state.getDailyScanProgressFailed = undefined
      })
      .addCase(getDailyScanProgress.fulfilled, (state, { payload }) => {
        state.getDailyScanProgressLoading = false
        state.dailyScanProgress = payload

        if (payload.remainingScan > 0) {
          const isPlural = payload.remainingScan > 1
          state.messages.push({
            owner: "kagami",
            texts: [
              `You can scan ${payload.remainingScan} meal${
                isPlural ? "s" : ""
              } today. Make sure to come back tomorrow for more! 😊`,
            ],
          })
        } else {
          state.messages.push(OUT_OF_DAILY_SCAN_MESSAGE)
        }
      })
      .addCase(getDailyScanProgress.rejected, (state, { payload }) => {
        state.getDailyScanProgressLoading = false
        state.getDailyScanProgressFailed = payload
        state.dailyScanProgress = undefined
      })

      .addCase(getMealUnits.pending, (state) => {
        state.getMealUnitsLoading = true
      })
      .addCase(getMealUnits.fulfilled, (state, { payload }) => {
        state.getMealUnitsLoading = false
        state.mealUnits = payload
      })

      .addCase(getMealUnits.rejected, (state, { payload }) => {
        state.getMealUnitsLoading = false
        state.getMealUnitsFailed = payload
      })

      .addCase(resetStoreAction, () => {
        return initialState
      })
  },
})

export const {
  uploadImage,
  updateFeedback,
  renameMeal,
  setStep,
  resetMealState,
  setMealDetails,
  removeUploadedImage,
} = searchSlice.actions

export default searchSlice.reducer
