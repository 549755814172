import styled from "styled-components"

export const MealComparisonWrapper = styled.div`
  padding: 16px;
  height: 100%;
  overflow-y: auto;
  background: var(--Schemes-Surface-Container-Low, #f5f7f7);
`

export const MealComparisonContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  align-items: stretch;
  width: 100%;
`

export const ColumnItem = styled.div`
  border: 1px solid #ddd;
  background: #ffffff;
  padding: 8px;
  box-sizing: border-box;
  border-radius: 8px;
  overflow: hidden;
  height: 100%;
`

export const Title = styled.h3`
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  background-color: #eaeaea;
  padding: 8px;
  margin-bottom: 16px;
  border-radius: 8px 8px 0 0;
  margin: 0;
`

export const UploadedPhotoWrapper = styled.div`
  width: 100%;
  padding: 24px;
  background-color: var(--Schemes-Surface-Container-Low, #f5f7f7);
`

export const UploadedImage = styled.img`
  border-radius: 24px;
  border: 6px solid var(--Schemes-Primary-Container-Variant-2, #0fbdd4);
  object-fit: cover;
  width: 100%;
  height: 100%;
`

export const InfoText = styled.p`
  margin: 6px 0;
`

export const InfoStrong = styled.strong`
  font-weight: bold;
`

export const CloseButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 44px;
  padding: 9px 4px;
`
