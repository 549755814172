import requester from "api/requester"
import {
  ICreateMealPayload,
  IDailyScanProgress,
  IDuplicateMealPayload,
  IFeedbackPayload,
  IIngredient,
  IIngredientOptionResponse,
  IMealResponse,
  IRawMeal,
} from "./types"

export interface IMealUnit {
  id: string
  name: string
}

interface IMealAPI {
  createMeals: (payload: ICreateMealPayload) => Promise<IRawMeal[]>
  createMeal: (payload: ICreateMealPayload) => Promise<IMealResponse>
  sendFeedback: (feedback: IFeedbackPayload, id: string) => Promise<any>
  processImage: (file: File) => Promise<any>
  getIngredients: () => Promise<IIngredientOptionResponse[]>
  duplicateMeal: (
    id: string,
    payload: IDuplicateMealPayload,
  ) => Promise<IMealResponse>

  getMealDetails: (id: string) => Promise<IMealResponse>

  getDailyScanProgress: () => Promise<IDailyScanProgress>
  getMealUnits: () => Promise<IMealUnit[]>
}

const getRequestCreateMealPayload = (payload: ICreateMealPayload) => {
  const requestPayload: any = {
    ...payload,
  }

  if (requestPayload.name) {
    requestPayload.name_of_dish = requestPayload.name
    delete requestPayload.name
  }

  if (requestPayload.ingredients) {
    requestPayload.ingredients = requestPayload.ingredients.map(
      (ingredient: IIngredient) => {
        const requestIngredient: any = {
          ...ingredient,
          ingredient_name: ingredient.name,
        }

        delete requestIngredient.name

        return requestIngredient
      },
    )
  }

  return requestPayload
}

const mealAPI: IMealAPI = {
  createMeals(payload) {
    const requestPayload = getRequestCreateMealPayload(payload)

    const formData = new FormData()
    Object.keys(requestPayload).forEach((key) => {
      const data = (requestPayload as any)[key]
      if (data === undefined || data === null || data === "") return

      if (key === "ingredients") {
        formData.append(
          key,
          new Blob([JSON.stringify(data)], {
            type: "application/json",
          }),
        )
      } else {
        formData.append(key, data)
      }
    })

    return requester.post("/scans", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
  },

  createMeal(payload) {
    const requestPayload = getRequestCreateMealPayload(payload)

    const formData = new FormData()
    Object.keys(requestPayload).forEach((key) => {
      const data = (requestPayload as any)[key]
      if (data === undefined || data === null || data === "") return

      if (key === "ingredients") {
        formData.append(
          key,
          new Blob([JSON.stringify(data)], {
            type: "application/json",
          }),
        )
      } else {
        formData.append(key, data)
      }
    })

    return requester.post("/meals", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
  },

  sendFeedback(feedback, id) {
    return requester.post(`meals/${id}/feedback`, feedback)
  },

  processImage(file) {
    return requester.post(
      "/images/process",
      { image: file },
      { headers: { "Content-Type": "multipart/form-data" } },
    )
  },

  getIngredients() {
    return requester.get("/ingredients")
  },

  duplicateMeal(id, payload) {
    return requester.post(`/meals/${id}/duplicate`, payload)
  },

  getMealDetails(id) {
    return requester.get(`/meals/${id}`)
  },

  async getDailyScanProgress() {
    const user = await requester.get("/users/info")

    return {
      remainingScan: user.started_scan_left,
    }
  },

  getMealUnits() {
    return requester.get("/meal-units")
  },
}

export default mealAPI
