import { useAppDispatch, useAppSelector } from "app/hooks"
import {
  createMeal,
  createMeals,
  duplicateMeal,
  getIngredientOptions,
  processImage,
  removeUploadedImage,
  renameMeal,
  resetMealState,
  sendFeedback,
  setStep,
  updateFeedback,
  uploadImage,
} from "../mealSlice"
import {
  IFeedback,
  IManualCreateMealPartialPayload,
  ILogMealStep,
} from "../types"
import { useNavigate, useSearchParams } from "react-router-dom"
import { IMealType } from "features/home/types"
import { useEffect, useState } from "react"
import PATHS from "router/paths"
import dayjs from "dayjs"
import { useFailed, useSuccess } from "features/notification/hooks"
import { DATE_PARAM_FORMAT } from "config"

export const useCreateMeal = () => {
  const createMealLoading = useAppSelector(
    (state) => state.meal.createMealLoading,
  )
  const createMealFailed = useAppSelector(
    (state) => state.meal.createMealFailed,
  )
  const file = useAppSelector((state) => state.meal.file)
  const imageId = useAppSelector((state) => state.meal.imageId)
  const displayImageType = useAppSelector(
    (state) => state.meal.displayImageType,
  )

  const [searchParams] = useSearchParams()

  const dispatch = useAppDispatch()
  const selectedDate = useAppSelector((state) => state.home.selectedDate)

  const handleCreateMeal = (manualPayload: IManualCreateMealPartialPayload) => {
    const type = searchParams.get("mealType")
    if (!type) {
      return
    }

    const isToDay = selectedDate && selectedDate.isSame(dayjs(), "day")
    const date = isToDay ? undefined : selectedDate?.format(DATE_PARAM_FORMAT)

    const mealType = type.toUpperCase() as IMealType
    if (displayImageType === "base64" && file) {
      dispatch(
        createMeal({ image: file, type: mealType, date, ...manualPayload }),
      )
    }

    if (displayImageType === "url" && imageId) {
      dispatch(
        createMeal({ id: imageId, type: mealType, date, ...manualPayload }),
      )
    }

    if (manualPayload.manual && !file && !imageId) {
      dispatch(createMeal({ id: "", type: mealType, date, ...manualPayload }))
    }
  }

  useFailed(createMealFailed)

  return {
    createMealLoading,
    createMealFailed,
    handleCreateMeal,
  }
}

export const useCreateMeals = () => {
  const createMealsLoading = useAppSelector(
    (state) => state.meal.createMealsLoading,
  )
  const createMealsFailed = useAppSelector(
    (state) => state.meal.createMealsFailed,
  )

  const aiData = useAppSelector((state) => state.meal.aiData)
  const userData = useAppSelector((state) => state.meal.userData)
  const file = useAppSelector((state) => state.meal.file)
  const imageId = useAppSelector((state) => state.meal.imageId)
  const displayImageType = useAppSelector(
    (state) => state.meal.displayImageType,
  )

  const [searchParams] = useSearchParams()

  const dispatch = useAppDispatch()
  const selectedDate = useAppSelector((state) => state.home.selectedDate)
  const clearMealsState = () => dispatch(resetMealState())

  const handleCreateMeal = () => {
    const type = searchParams.get("mealType")
    if (!type) {
      return
    }

    const isToDay = selectedDate && selectedDate.isSame(dayjs(), "day")
    const date = isToDay ? undefined : selectedDate?.format(DATE_PARAM_FORMAT)

    const mealType = type.toUpperCase() as IMealType
    if (displayImageType === "base64" && file) {
      dispatch(createMeals({ image: file, type: mealType, date }))
    }

    if (displayImageType === "url" && imageId) {
      dispatch(createMeals({ id: imageId, type: mealType, date }))
    }
  }

  useFailed(createMealsFailed)

  return {
    createMealsLoading,
    createMealsFailed,
    aiData,
    userData,
    clearMealsState,
    handleCreateMeal,
  }
}

export const useUploadImage = () => {
  const imageBase64Encoded = useAppSelector(
    (state) => state.meal.imageBase64Encoded,
  )

  const dispatch = useAppDispatch()

  const onUploadImage = (base64: string, file: File) => {
    dispatch(uploadImage({ base64, file }))
  }

  const onRemoveUploadedImage = () => {
    dispatch(removeUploadedImage())
  }

  return {
    imageBase64Encoded,
    onUploadImage,
    onRemoveUploadedImage,
  }
}

export const useFeedback = (callback?: () => void) => {
  const dispatch = useAppDispatch()
  const handleUpdateFeedback = (model: any) => {
    dispatch(updateFeedback(model))
  }

  const feedback = useAppSelector((state) => state.meal.feedback)
  const sendFeedbackSuccess = useAppSelector(
    (state) => state.meal.sendFeedbackSuccess,
  )

  const sendFeedbackFailed = useAppSelector(
    (state) => state.meal.sendFeedbackFailed,
  )
  const sendFeedbackLoading = useAppSelector(
    (state) => state.meal.sendFeedbackLoading,
  )

  const handleSendFeedback = (comment: string) => {
    if (feedback) {
      dispatch(sendFeedback({ ...feedback, comment }))
    }
  }

  const onSubmitFeedback = (feedback: IFeedback) => {
    dispatch(sendFeedback(feedback))
  }

  useFailed(sendFeedbackFailed)
  useSuccess(sendFeedbackSuccess, { message: "" }, callback)

  return {
    feedback,
    handleUpdateFeedback,
    handleSendFeedback,
    onSubmitFeedback,
    sendFeedbackLoading,
  }
}

export const useResetMealState = () => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    return () => {
      dispatch(resetMealState())
    }
  }, [dispatch])
}

export const useProcessImage = () => {
  const dispatch = useAppDispatch()

  const imageUrl = useAppSelector((state) => state.meal.imageUrl)
  const processImageLoading = useAppSelector(
    (state) => state.meal.processImageLoading,
  )

  const displayImageType = useAppSelector(
    (state) => state.meal.displayImageType,
  )

  const handleProcessImage = (file: File) => {
    dispatch(processImage(file))
  }

  return {
    imageUrl,
    processImageLoading,
    handleProcessImage,
    displayImageType,
  }
}

export const useRenameMeal = () => {
  const createdMeal = useAppSelector((state) => state.meal.createdMeal)
  const [name, setName] = useState(createdMeal?.name || "")

  const dispatch = useAppDispatch()
  useEffect(() => {
    setName(createdMeal?.name || "")
  }, [createdMeal])

  const onChange = (value: string) => {
    setName(value)
  }

  const onSubmit = () => {
    dispatch(renameMeal(name))
  }

  return {
    name,
    onChange,
    onSubmit,
  }
}

export const useLogMealStep = () => {
  const dispatch = useAppDispatch()

  const handleSetStep = (step: ILogMealStep) => {
    dispatch(setStep(step))
  }

  return handleSetStep
}

export const useIngredientOptions = () => {
  const ingredientOptions = useAppSelector(
    (state) => state.meal.ingredientOptions,
  )
  const getIngredientOptionsLoading = useAppSelector(
    (state) => state.meal.getIngredientOptionsLoading,
  )
  const getIngredientOptionsFailed = useAppSelector(
    (state) => state.meal.getIngredientOptionsFailed,
  )

  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(getIngredientOptions())
  }, [dispatch])

  return {
    ingredientOptions,
    getIngredientOptionsLoading,
    getIngredientOptionsFailed,
  }
}

export const useDuplicateMeal = () => {
  const duplicatedMealLoading = useAppSelector(
    (state) => state.meal.duplicatedMealLoading,
  )
  const duplicatedMealFailed = useAppSelector(
    (state) => state.meal.duplicatedMealFailed,
  )
  const duplicatedMealSuccess = useAppSelector(
    (state) => state.meal.duplicatedMealSuccess,
  )

  const selectedDate = useAppSelector((state) => state.home.selectedDate)

  const dispatch = useAppDispatch()

  const [searchParams] = useSearchParams()
  const handleDuplicateMeal = (feedback: IFeedback) => {
    const type = searchParams.get("mealType")
    if (selectedDate && type) {
      const isToDay = selectedDate && selectedDate.isSame(dayjs(), "day")
      dispatch(
        duplicateMeal({
          id: feedback.id,
          payload: {
            date: isToDay ? undefined : selectedDate.format(DATE_PARAM_FORMAT),
            type: type.toUpperCase() as IMealType,
            servings: feedback.servings,
            name_of_dish: feedback.name,
            comment: feedback.comment ?? "",
            ingredients: feedback.ingredients.map((ingredient) => ({
              ...ingredient,
              ingredient_name: ingredient.name,
            })),
          },
        }),
      )
    }
  }

  const navigate = useNavigate()

  const homePath = useHomePathWithSelectedDate()

  useEffect(() => {
    if (duplicatedMealSuccess) {
      navigate(homePath)
      dispatch(resetMealState())
    }
  }, [duplicatedMealSuccess])

  useFailed(duplicatedMealFailed)

  return {
    duplicatedMealLoading,
    duplicatedMealFailed,
    duplicatedMealSuccess,
    handleDuplicateMeal,
  }
}

export const useHomePathWithSelectedDate = () => {
  const selectedDate = useAppSelector((state) => state.home.selectedDate)

  return (
    PATHS.app.root +
    "?" +
    new URLSearchParams({
      date: selectedDate?.format(DATE_PARAM_FORMAT) ?? "",
    }).toString()
  )
}

export const useBackToHome = () => {
  const navigate = useNavigate()
  const homePath = useHomePathWithSelectedDate()
  return () => {
    navigate(homePath)
  }
}

export const useSetMealTypeParams = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const mealType = searchParams.get("mealType")
  useEffect(() => {
    const MEAL_TYPES = ["breakfast", "lunch", "dinner", "snack"]
    if (!MEAL_TYPES.includes(mealType as string)) {
      setSearchParams({ mealType: "breakfast" })
    }
  }, [])

  return mealType
}

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
const safariVersion = navigator.userAgent.match(/Version\/(\d+)/)
let isSafari17 = isSafari && safariVersion && parseInt(safariVersion[1]) >= 17

const getBase64 = (file: File | Blob, callback: (url: string) => void) => {
  const reader = new FileReader()
  reader.addEventListener("load", () => callback(reader.result as string))
  reader.readAsDataURL(file)
}

export const useImageUploader = () => {
  const { onUploadImage, imageBase64Encoded, onRemoveUploadedImage } =
    useUploadImage()
  const {
    processImageLoading,
    handleProcessImage,
    imageUrl,
    displayImageType,
  } = useProcessImage()

  const onImageChange = (file?: File) => {
    if (file) {
      const isHeicFile = file.type === "image/heic"
      const isHeifFile = file.type === "image/heif"
      const shouldConvert = isHeicFile || isHeifFile

      if (shouldConvert && !isSafari17) {
        handleProcessImage(file)
      } else {
        getBase64(file, (base64) => {
          onUploadImage(base64, file)
        })
      }
    }
  }

  const displayImageUrl =
    displayImageType === "base64" ? imageBase64Encoded : imageUrl

  return {
    onImageChange,
    onRemoveUploadedImage,
    processImageLoading,
    displayImageUrl,
  }
}

export const useBackToHomeAfterCreateManualMeal = () => {
  const createdMeal = useAppSelector((state) => state.meal.createdMeal)
  const backToHome = useBackToHome()

  useEffect(() => {
    if (createdMeal) {
      backToHome()
    }
  }, [createdMeal])
}
