import { Fragment, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { FlexBox } from "components/Common/FlexBox"
import CloseIcon from "components/Icons/CloseIcon"
import {
  useCreateMeals,
  useHomePathWithSelectedDate,
  useProcessImage,
  useUploadImage,
} from "features/meal/hooks/hooks"

import {
  MealComparisonWrapper,
  MealComparisonContainer,
  Title,
  UploadedPhotoWrapper,
  UploadedImage,
  InfoText,
  InfoStrong,
  CloseButtonWrapper,
  ColumnItem,
} from "./styled"

const MealComparisonPage = () => {
  const { aiData, userData, clearMealsState, createMealsLoading } =
    useCreateMeals()
  const { imageUrl, displayImageType } = useProcessImage()
  const { imageBase64Encoded } = useUploadImage()

  const navigate = useNavigate()
  const homePath = useHomePathWithSelectedDate()

  const handleClose = () => {
    navigate(homePath)
  }

  useEffect(() => {
    return () => {
      clearMealsState()
    }
  }, [])

  useEffect(() => {
    if (!aiData.length || !userData.length) {
      navigate(homePath)
    }
  }, [])

  const formatCalorie = (value: number) => {
    return value.toFixed(1)
  }

  return (
    <MealComparisonWrapper>
      <Fragment>
        <CloseButtonWrapper>
          <FlexBox $alignItems="center">
            <CloseIcon
              onClick={handleClose}
              style={{
                cursor: createMealsLoading ? "not-allowed" : "pointer",
                opacity: createMealsLoading ? 0.5 : 1,
              }}
            />
          </FlexBox>
        </CloseButtonWrapper>
      </Fragment>

      {displayImageType === "base64" && imageBase64Encoded && (
        <UploadedPhotoWrapper>
          <UploadedImage src={imageBase64Encoded} />
        </UploadedPhotoWrapper>
      )}
      {displayImageType === "url" && imageUrl && (
        <UploadedPhotoWrapper>
          <UploadedImage src={imageUrl} />
        </UploadedPhotoWrapper>
      )}

      <MealComparisonContainer>
        <ColumnItem>
          <Title>AI Data</Title>
        </ColumnItem>

        <ColumnItem>
          <Title>Food Journal UI</Title>
        </ColumnItem>

        {aiData.map((item, index) => (
          <Fragment key={index}>
            <ColumnItem>
              <InfoText>
                <InfoStrong>Name:</InfoStrong> {item.name}
              </InfoText>
              <InfoText>
                <InfoStrong>Portion:</InfoStrong> {item.portion}
              </InfoText>
              <InfoText>
                <InfoStrong>Unit:</InfoStrong> {item.unit}
              </InfoText>
              <InfoText>
                <InfoStrong>Weight:</InfoStrong> {item.weight}
              </InfoText>
            </ColumnItem>

            <ColumnItem>
              <InfoText>
                <InfoStrong>Name:</InfoStrong> {userData[index]?.name}
              </InfoText>
              <InfoText>
                <InfoStrong>Portion:</InfoStrong> {userData[index]?.portion}
              </InfoText>
              <InfoText>
                <InfoStrong>Unit:</InfoStrong> {userData[index]?.unit}
              </InfoText>
              <InfoText>
                <InfoStrong>Calorie:</InfoStrong>{" "}
                {formatCalorie(userData[index]?.calorie)}
              </InfoText>
            </ColumnItem>
          </Fragment>
        ))}
      </MealComparisonContainer>
    </MealComparisonWrapper>
  )
}

export default MealComparisonPage
